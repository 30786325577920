import React from "react";
import { RequirementsSearch, ExperiencesSearch, InspectSearch } from "./search";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { theme } from "./theme";
import Layout from "./layout/Layout";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import {
  FirebaseAuthConsumer,
  FirebaseAuthProvider,
} from "@react-firebase/auth";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const firebaseConfig = {
  apiKey: "AIzaSyB8IvyjzGFxsfehDadzPRXK-ZaCj77IpXs",
  authDomain: "optibox-compliance.firebaseapp.com",
  projectId: "optibox-compliance",
  storageBucket: "optibox-compliance.appspot.com",
  messagingSenderId: "839456042906",
  appId: "1:839456042906:web:49413e2a6991f970795423",
  measurementId: "G-DRKLQE3T1Q",
};

firebase.initializeApp(firebaseConfig);

function FirebaseAnalytics() {
  const location = useLocation();
  useEffect(() => {
    const page_path = location.pathname + location.search;
    firebase.analytics().setCurrentScreen(page_path);
    firebase.analytics().logEvent("page_view", { page_path });
  }, [location]);
  return null;
}

export default function App() {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Router>
          <FirebaseAnalytics />
          <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
            <FirebaseAuthConsumer>
              {({ isSignedIn }) => {
                if (isSignedIn === true) {
                  return (
                    <Switch>
                      <Route path="/experiences">
                        <Layout>
                          <Helmet>
                            <title>Experiences - OptiCompliance</title>
                          </Helmet>
                          <ExperiencesSearch />
                        </Layout>
                      </Route>
                      <Route path="/requirements">
                        <Layout>
                          <Helmet>
                            <title>Requirements - OptiCompliance</title>
                          </Helmet>
                          <RequirementsSearch />
                        </Layout>
                      </Route>
                      <Route path="/inspect">
                        <Layout>
                          <Helmet>
                            <title>Inspect - OptiCompliance</title>
                          </Helmet>
                          <InspectSearch />
                        </Layout>
                      </Route>
                      <Route path="/">
                        <Layout>
                          <Helmet>
                            <title>Requirements - OptiCompliance</title>
                          </Helmet>
                          <RequirementsSearch />
                        </Layout>
                      </Route>
                    </Switch>
                  );
                } else {
                  return (
                    <Layout>
                      <Helmet>
                        <title>Sign in - OptiCompliance</title>
                      </Helmet>
                      <div>
                        Hi! Do get in touch if you're interested.{" "}
                        <span role="img" aria-label="Happy face">
                          🤩
                        </span>
                      </div>
                    </Layout>
                  );
                }
              }}
            </FirebaseAuthConsumer>
          </FirebaseAuthProvider>
        </Router>
      </ThemeProvider>
    </>
  );
}
