import React from "react";

import { isFieldValueWrapper } from "../types/FieldValueWrapper";
import * as PropTypes from "prop-types";
import { Box, Breadcrumbs, Chip, Paper, Typography } from "@material-ui/core";

function getFieldType(result, field, type) {
  if (result[field]) return result[field][type];
}

function getRaw(result, field) {
  return getFieldType(result, field, "raw");
}

function getSnippet(result, field) {
  return getFieldType(result, field, "snippet");
}

function htmlEscape(str) {
  if (!str) return "";

  return String(str)
    .replace(/&/g, "&amp;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#39;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
}

function getEscapedField(result, field) {
  // Fallback to raw values here, because non-string fields
  // will not have a snippet fallback. Raw values MUST be html escaped.
  const safeField =
    getSnippet(result, field) || htmlEscape(getRaw(result, field));
  return Array.isArray(safeField) ? safeField.join(", ") : safeField;
}

function getEscapedFields(result) {
  return Object.keys(result).reduce((acc, field) => {
    // If we receive an arbitrary value from the response, we may not properly
    // handle it, so we should filter out arbitrary values here.
    //
    // I.e.,
    // Arbitrary value: "_metaField: '1939191'"
    // vs.
    // FieldValueWrapper: "_metaField: {raw: '1939191'}"
    if (!isFieldValueWrapper(result[field])) return acc;
    return { ...acc, [field]: getEscapedField(result, field) };
  }, {});
}

function SearchResult({
  className,
  result,
  onClickLink,
  titleField,
  urlField,
  ...rest
}) {
  const {
    id,
    type,
    place,
    topic_high_level,
    topic_detailed,
    paragraph,
  } = getEscapedFields(result);
  const question = getRaw(result, "question");
  const explanation = getRaw(result, "explanation");
  const notes = getRaw(result, "notes");

  return (
    <Paper key={id} style={{ margin: "10px" }} variant={"outlined"}>
      <Box p={2}>
        <Typography variant="caption" color="textPrimary">
          {type && (
            <>
              <span dangerouslySetInnerHTML={{ __html: type }} /> /{" "}
            </>
          )}
          {place && (
            <>
              <span dangerouslySetInnerHTML={{ __html: place }} /> /{" "}
            </>
          )}
          {topic_high_level && (
            <>
              <span dangerouslySetInnerHTML={{ __html: topic_high_level }} /> /{" "}
            </>
          )}
          {topic_detailed && (
            <>
              <span dangerouslySetInnerHTML={{ __html: topic_detailed }} /> /{" "}
            </>
          )}
          {paragraph && (
            <>
              <span dangerouslySetInnerHTML={{ __html: paragraph }} /> /{" "}
            </>
          )}
        </Typography>
        {question && (
          <Box pt={2} pb={2}>
            <Typography variant="body1" component="p" gutterBottom>
              <span
                dangerouslySetInnerHTML={{
                  __html: htmlEscape(getRaw(result, "question")),
                }}
              />
            </Typography>
          </Box>
        )}
        {explanation && (
          <Box pt={2} pb={2}>
            <Typography variant="h6">Explanation</Typography>
            <Typography variant="body1" component="p" gutterBottom>
              <span
                dangerouslySetInnerHTML={{
                  __html: htmlEscape(getRaw(result, "explanation")),
                }}
              />
            </Typography>
          </Box>
        )}
        {notes && (
          <Box pt={2} pb={2}>
            <Typography variant="h6">Notes</Typography>
            <Typography variant="body1" component="p" gutterBottom>
              <span
                dangerouslySetInnerHTML={{
                  __html: htmlEscape(getRaw(result, "notes")),
                }}
              />
            </Typography>
          </Box>
        )}
      </Box>
    </Paper>
  );
}

SearchResult.propTypes = {
  result: PropTypes.object.isRequired,
  onClickLink: PropTypes.func.isRequired,
  className: PropTypes.string,
  titleField: PropTypes.string,
  urlField: PropTypes.string,
};

export default SearchResult;
