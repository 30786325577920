import React, { createContext, useContext } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export const BackdropContext = createContext({
  show: false,
  setShow: () => {},
});

export const BackdropContextProvider = BackdropContext.Provider;
export const BackdropContextConsumer = BackdropContext.Consumer;

export default () => {
  const { show, setShow } = useContext(BackdropContext);
  const classes = useStyles();
  const handleClose = () => {
    setShow(false);
  };

  return (
    <Backdrop className={classes.backdrop} open={show} onClick={handleClose}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
