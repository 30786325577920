import React from "react";

import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";

import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  SearchBox,
  Results,
  PagingInfo,
  ResultsPerPage,
  Paging,
  Sorting,
  WithSearch,
} from "@elastic/react-search-ui";
import { Layout, MultiCheckboxFacet } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";

import {
  buildAutocompleteQueryConfig,
  buildSearchOptionsFromConfig,
  buildSortOptionsFromConfig,
  getConfig,
} from "../config/experiences-config-helper";

import { BackdropContextConsumer } from "../../layout/Backdrop";

import "../Search.css";
import SearchResult from "./SearchResult";
import SearchResults from "./SearchResults";

const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();
const connector = new AppSearchAPIConnector({
  searchKey,
  engineName,
  hostIdentifier,
  endpointBase,
});
const config = {
  searchQuery: {
    disjunctiveFacets: ["raised_by"],
    facets: {
      raised_by: { type: "value", size: 10 },
    },
    ...buildSearchOptionsFromConfig(),
  },
  autocompleteQuery: buildAutocompleteQueryConfig(),
  apiConnector: connector,
  alwaysSearchOnInitialLoad: true,
};

export default function Search() {
  return (
    <SearchProvider config={config}>
      <BackdropContextConsumer>
        {({ setShow }) => (
          <WithSearch
            mapContextToProps={({ wasSearched, isLoading }) => ({
              wasSearched,
              isLoading,
            })}
          >
            {({ wasSearched, isLoading }) => {
              setShow(isLoading);
              return (
                <div className="App">
                  <ErrorBoundary>
                    <Layout
                      header={<SearchBox autocompleteSuggestions={true} />}
                      sideContent={
                        <div>
                          {wasSearched && (
                            <Sorting
                              label={"Sort by"}
                              sortOptions={buildSortOptionsFromConfig()}
                            />
                          )}
                          <Facet
                            field="raised_by"
                            label="Raised by"
                            view={MultiCheckboxFacet}
                            filterType="any"
                          />
                        </div>
                      }
                      bodyContent={
                        <Results
                          titleField={getConfig().titleField}
                          urlField={getConfig().urlField}
                          shouldTrackClickThrough={true}
                          resultView={SearchResult}
                          view={SearchResults}
                        />
                      }
                      bodyHeader={
                        <React.Fragment>
                          {wasSearched && <PagingInfo />}
                          {wasSearched && <ResultsPerPage />}
                        </React.Fragment>
                      }
                      bodyFooter={<Paging />}
                    />
                  </ErrorBoundary>
                </div>
              );
            }}
          </WithSearch>
        )}
      </BackdropContextConsumer>
    </SearchProvider>
  );
}
