import { createMuiTheme } from "@material-ui/core";

const baseTheme = {
  typography: {
    fontFamily: ['"Roboto"', "sans-serif"].join(","),
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: 48,
      fontWeight: 700,
    },
    h2: {
      fontSize: 36,
      fontWeight: 700,
    },
    h3: {
      fontSize: 24,
      fontWeight: 700,
    },
    h4: {
      fontSize: 24,
      fontWeight: 500,
    },
    h5: {
      fontSize: 18,
      fontWeight: 600,
    },
    h6: {
      fontSize: 18,
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: 18,
      fontWeight: 700,
    },
    subtitle2: {
      fontSize: 16,
      fontWeight: 500,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      fontSize: 12,
      fontWeight: 400,
    },
    button: {
      fontSize: 18,
      fontWeight: 600,
    },
    caption: {
      fontSize: 10,
      fontWeight: 400,
    },
    overline: {
      fontSize: 18,
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: "#096b7f",
    },
    secondary: {
      main: "#efcf8c",
    },
  },
  shape: {
    borderRadius: 0,
  },
};

export const theme = createMuiTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    type: "light",
  },
  overrides: {
    //override default styling of components
    MuiCard: {
      root: {
        boxShadow: "none",
      },
    },
    MuiCardContent: {
      root: {
        padding: 40,
        "&:last-child": {
          paddingBottom: 40,
        },
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
    },
    MuiButton: {
      contained: {
        boxShadow: "none",
      },
    },
    MuiDialogTitle: {
      root: {
        padding: 40,
        paddingBottom: 8,
      },
    },
    MuiDialogContent: {
      root: {
        paddingLeft: 40,
        paddingRight: 40,
      },
    },
    MuiDialogActions: {
      root: {
        padding: 40,
        paddingTop: 8,
      },
    },
  },
});
