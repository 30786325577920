import React, { useState } from "react";
import { FirebaseAuthConsumer } from "@react-firebase/auth";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  links: {
    flexGrow: 1,
    marginLeft: theme.spacing(5),
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

const HeaderMenu = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [signInDialogOpen, setSignInDialogOpen] = useState(false);

  const openPopupMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopupMenu = () => {
    setAnchorEl(null);
  };

  return (
    <FirebaseAuthConsumer>
      {({ isSignedIn, user, firebase }) => {
        if (isSignedIn) {
          return (
            <>
              <Typography className={classes.links}>
                <Link variant={"h5"} component={RouterLink} to="/requirements">
                  Requirements
                </Link>
                <Link variant={"h5"} component={RouterLink} to="/experiences">
                  Experiences
                </Link>
                <Link variant={"h5"} component={RouterLink} to="/inspect">
                  Inspect
                </Link>
              </Typography>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                color={"inherit"}
                onClick={openPopupMenu}
              >
                {user.email} <KeyboardArrowDownIcon />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={anchorEl !== null}
                onClose={closePopupMenu}
                anchorOrigin={{ horizontal: "left", vertical: "top" }}
              >
                <MenuItem
                  onClick={() => {
                    firebase.app().auth().signOut();
                    closePopupMenu();
                  }}
                >
                  Sign out
                </MenuItem>
              </Menu>
            </>
          );
        } else {
          return (
            <>
              <Typography className={classes.links} />
              <Button
                color={"inherit"}
                onClick={() => {
                  setSignInDialogOpen(true);
                }}
              >
                SIGN IN
              </Button>
              <SignInDialog
                isOpen={signInDialogOpen}
                onClose={() => setSignInDialogOpen(false)}
                onClick={(user, password) => {
                  firebase
                    .app()
                    .auth()
                    .signInWithEmailAndPassword(user, password);
                }}
              />
            </>
          );
        }
      }}
    </FirebaseAuthConsumer>
  );
};

const SignInDialog = ({ isOpen, onClose, onClick }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        <form
          noValidate
          autoComplete="off"
          onSubmit={() => onClick(email, password)}
        >
          <div>
            <TextField
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Password"
              type="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => onClick(email, password)}
          color="primary"
          autoFocus
        >
          Sign in
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HeaderMenu;
