import { Box, Grid } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import logo from "./logo.png";
import React from "react";
import HeaderMenu from "./HeaderMenu";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#022f3f",
    color: "#edeeee",
    zIndex: theme.zIndex.drawer + 1,
  },
  logo: {
    height: 46,
    marginLeft: 30,
  },
}));

const Header = () => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" elevation={0} className={classes.appBar}>
      <Toolbar>
        <Box>
          <a href="/">
            <img className={classes.logo} src={logo} alt="Indusenz logo" />
          </a>
        </Box>
        <Grid container alignItems="center">
          <HeaderMenu />
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
