import { Box, Container, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import Header from "./Header";
import Backdrop, { BackdropContextProvider } from "./Backdrop";

const useStyles = makeStyles((theme) => ({
  box: {
    height: "100%",
  },
  main: {
    overflow: "auto",
    flexGrow: 1,
    padding: 0,
    paddingTop: 0,
    backgroundColor: "#dfd8c6",
  },
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
  },
}));

const Layout = ({ children }) => {
  const classes = useStyles();
  const [showBackdrop, setShowBackdrop] = useState(false);

  return (
    <BackdropContextProvider
      value={{
        show: showBackdrop,
        setShow: (val) => {
          setShowBackdrop(val);
        },
      }}
    >
      <Box display="flex" className={classes.box}>
        <Header />
        <div className={classes.main}>
          <Toolbar />
          <Container className={classes.container}>{children}</Container>
        </div>
      </Box>
      <Backdrop />
    </BackdropContextProvider>
  );
};

export default Layout;
