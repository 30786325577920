import PropTypes from "prop-types";
import React from "react";

function SearchResults({ children, className, ...rest }) {
  return <>{children}</>;
}

SearchResults.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default SearchResults;
